import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
// @ts-expect-error Could not find @types/react-recompose on npm
import { branch, compose, renderComponent } from 'react-recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import LinearProgress from '@mui/material/LinearProgress';
import Hidden from '@mui/material/Hidden';
import Divider from '@mui/material/Divider';
import UserInfo from './components/UserInfo';
import MainMenu from './menu/MainMenu';
import LoadingPage from './components/loading';
import { push, RouterAction } from 'react-router-redux';
import stylesConfig, { appConfig } from './config';
import { closeMobileMenu, openMobileMenu, setMenuOpen, setMenuSelected } from './actions/app';
import { getChatCounter, setNotificationIgnore, updateMessages } from './actions/chat';
import SockJS from 'sockjs-client';
import webstomp, { Client } from 'webstomp-client';
// @ts-expect-error Could not find @types/react-web-notification on npm
import Notification from 'react-web-notification';
import TopBarTestAccountInfo from './components/testAccount/TopBarTestAccountInfo';
import TestAccountDrawerWrapper from './components/testAccount/TestAccountDrawerWrapper';
import TestAccountWelcomeDialog from './components/testAccount/TestAccountWelcomeDialog';
import TestAccountExpiredDialog from './components/testAccount/TestAccountExpiredDialog';
//import StatusPage from './components/status';
import { grey } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { leavePreviewMode } from './actions/appearance';
import { MenuEntry } from './types/menu';
import { SupportedLang } from './messages';
import { AppState } from './reducers';
import { Theme } from '@mui/material';
import { RouterState } from 'connected-react-router';
import { LocationDescriptor } from 'history';
import { LoginState } from './reducers/login';
import { makeStyles } from '@mui/styles';
import { useLogoutInterceptor } from './hooks/useLogoutInterceptor';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    position: 'fixed',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: theme.palette.custom.appBarColor,
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    flex: '1',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    paddingTop: 50,
    marginTop: 56,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  footer: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.custom.logoBackgroundColor ? theme.palette.custom.logoBackgroundColor : grey[200],
  },
  footerLink: {
    textDecoration: 'none',
    color: grey[900],
    '&:hover': {
      color: grey[700],
    },
  },
  tcLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: grey[700],
    },
  },
}));

type Props = {
  readonly module: React.ReactNode;
  readonly authenticated: boolean;
  readonly loginFailed: boolean;
  readonly userData: $TSFixMe;
  readonly userInfo: LoginState;
  readonly mobileMenuOpen: boolean;
  readonly location: string;
  readonly loadingMenu: boolean;
  readonly menuItems: MenuEntry[];
  readonly router: RouterState;
  readonly menuOpen: string[];
  readonly menuSelected: string;
  readonly lang: SupportedLang;
  readonly logo: string;
  readonly customerId?: number;
  readonly notificationBody: string;
  readonly notificationIcon: $TSFixMe;
  readonly notificationIgnore: boolean;
  readonly testAccount: boolean;
  readonly channel: string;
  readonly isPreviewMode: boolean;

  readonly openMobileMenu: () => void;
  readonly closeMobileMenu: () => void;
  readonly setMenuOpen: (menuOpen: string[]) => void;
  readonly setMenuSelected: (menuSelected: string) => void;
  readonly push: (location: LocationDescriptor) => RouterAction;
  readonly updateMessages: (messages: $TSFixMe) => void;
  readonly setNotificationIgnore: (flag: boolean) => void;
  readonly getChatCounter: () => void;
  readonly leavePreviewMode: () => void;
};

const BaseView = (props: Props) => {
  let client: Client | undefined = undefined;

  useLogoutInterceptor();

  useEffect(() => {
    const { router, menuItems, setMenuOpen, setMenuSelected, menuSelected, push, getChatCounter } = props;

    if (router.action === 'POP') {
      const allItems: MenuEntry[] = [
        ...[].concat.apply(
          menuItems,
          // @ts-expect-error we should refactor it and use flatMap
          menuItems.map((e) => e.subMenu)
        ),
      ];
      const item = allItems.find((e) => e.link === router.location.pathname);
      if (item) {
        setMenuOpen([item.parent ? item.parent : item.label]);
        setMenuSelected(item.label);
      } else {
        const item = allItems.find((e) => e.label === menuSelected);
        if (menuSelected !== 'nav.dashboard' && item && router.location.pathname !== item.link) {
          push(item.link);
        }
      }
    }
    connectSocket();
    getChatCounter();

    return () => {
      if (client && client.connected) client.disconnect();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const onUpdate = ({ body = '{}' }) => {
    const message = JSON.parse(body);
    props.updateMessages(message);
  };
  const subscribeUpdates = () => {
    client?.subscribe(`/topics/chat/${props.customerId}`, onUpdate, {
      'X-Authorization': `Bearer ${localStorage.getItem('token')}`,
    });
  };

  const connectSocket = () => {
    const token = localStorage.getItem('token');
    // @ts-expect-error URL_WS can be undefined - we should define AppConfig interface
    const socket = new SockJS(appConfig.URL_WS);
    client = webstomp.over(socket, {
      debug: Boolean(appConfig.DEBUG_WS === 'true'),
      protocols: webstomp.VERSIONS.supportedProtocols(),
      // @ts-expect-error transports option does not exist - to verify!
      transports: ['websocket'],
    });
    client.connect({ 'X-Authorization': 'Bearer ' + token }, subscribeUpdates);
  };
  const {
    module,
    location,
    loadingMenu,
    mobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
    menuItems,
    lang,
    logo,
    testAccount,
  } = props;

  const classes = useStyles();

  const TCLink = lang === SupportedLang.EN ? stylesConfig.TC_LINK_EN : stylesConfig.TC_LINK_DE;
  const showAppName: boolean = stylesConfig.SHOW_APPNAME
    ? parseInt(stylesConfig.SHOW_APPNAME) > 0
    : false;

  const allItems: MenuEntry[] = [
    ...[].concat.apply(
      menuItems,
      // @ts-expect-error we should refactor it and use flatMap
      menuItems.map((e) => e.subMenu)
    ),
  ];
  const item = allItems.find((e) => e.link === location);
  let label = '';
  let subLabel = '';
  if (item) {
    label = item.parent || '';
    subLabel = item.label;
  }
  const drawerContent = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowX: 'hidden',
      }}
    >
      <div className={classes.toolbar} style={{ display: 'flex' }}>
        {logo && (
          <a href={`${stylesConfig.LOGO_LINK}`} target="_blank" rel="noopener noreferrer" style={{ flex: 1 }}>
            <img src={logo} alt={`${stylesConfig.LOGO_ALT}`} style={{ maxHeight: 45, margin: 5 }} />
          </a>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <Divider />
        {testAccount && (
          <Hidden mdUp>
            <div style={{ paddingTop: '10px', paddingLeft: '20px' }}>
              <TopBarTestAccountInfo />
            </div>
          </Hidden>
        )}
        {loadingMenu ? <LinearProgress color="secondary" style={{ height: 1 }} /> : <MainMenu />}
      </div>
      <div>
        {/* {(stylesConfig.THEME === "telxira" || (stylesConfig.THEME === "sunrise" && customerId === 30)) && ( // eslint-disable-line
          <StatusPage />
        )} */}
        <Divider />

        <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
          <Typography variant='caption' component='div'>
            {`v${stylesConfig.APP_VERSION}`}
          </Typography>
          { stylesConfig.FOOTER_TEXT && stylesConfig.FOOTER_LINK && (
            <Typography variant='caption' component='div'>
              <a target='_blank' rel='noopener noreferrer' className={classes.footerLink} href={stylesConfig.FOOTER_LINK}>
                {`© ${new Date().getFullYear()} | ${stylesConfig.FOOTER_TEXT}`}
              </a>
            </Typography>
          )}
          { TCLink && (
            <Typography variant='caption' component='div'>
              <a target='_blank' rel='noopener noreferrer' className={classes.tcLink} href={TCLink}>
                <FormattedMessage id='dashboard.terms.conditions' />
              </a>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.navIconHide}
            style={{ color: grey[100] }}
            onClick={openMobileMenu}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          { props.isPreviewMode && (
            <Alert severity="info" sx={{ mx: 1 }} action={
              <Button
                size="small"
                color="primary"
                onClick={() => props.leavePreviewMode()}
              >
                <FormattedMessage id="dashboard.button.leave.preview.mode" />
              </Button>
            }>
              <FormattedMessage id="dashboard.info.preview.mode" />
            </Alert>
          )}

          <Typography variant="h6" color="inherit" noWrap style={{ flex: 1 }}>
            { showAppName && (
              <FormattedMessage id="dashboard.app.name" values={{ appname: stylesConfig.APPNAME }} />
            )}
          </Typography>
          {testAccount && (
            <Hidden mdDown>
              <TopBarTestAccountInfo />
            </Hidden>
          )}
          <UserInfo />
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h5" color="primary">
            {label && <FormattedMessage id={label} />} {label && ' / '} {subLabel && <FormattedMessage id={subLabel} />}
          </Typography>
          <Divider style={{ marginBottom: 20 }} />
          {module}
        </div>
      </div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileMenuOpen}
          onClose={closeMobileMenu}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Notification
        ignore={props.notificationIgnore}
        onClick={() => {
          window.focus();
          props.setNotificationIgnore(true);
        }}
        title={`New message`}
        options={{
          body: props.notificationBody,
          icon: props.notificationIcon,
        }}
      />
      <TestAccountDrawerWrapper />
      <TestAccountWelcomeDialog />
      <TestAccountExpiredDialog />
    </React.Fragment>
  );
};

export default compose(
  withRouter,
  connect(
    (store: AppState) => ({
      authenticated: store.login.authenticated,
      loginFailed: store.login.failed,
      userData: store.login.userData,
      userInfo: store.login,
      mobileMenuOpen: store.app.mobileMenuOpen,
      location: store.router.location.pathname,
      loadingMenu: store.app.loadingMenu,
      menuItems: store.app.menu,
      router: store.router,
      menuOpen: store.app.menuOpen,
      menuSelected: store.app.menuSelected,
      lang: store.locale.lang,
      logo: store.app.logo,
      customerId: store.login.userData.customerId,
      notificationBody: store.chat.notificationBody,
      notificationIcon: store.chat.notificationIcon,
      notificationIgnore: store.chat.notificationIgnore,
      testAccount: store.testAccount.accountInfo.customerType === 'DEMO',
      channel: store.chat.channel,
      isPreviewMode: store.appearance.previewMode,
    }),
    {
      openMobileMenu,
      closeMobileMenu,
      setMenuOpen,
      setMenuSelected,
      push,
      updateMessages,
      setNotificationIgnore,
      getChatCounter,
      leavePreviewMode
    }
  ),
  branch((props: Props) => !props.userInfo.authenticated, renderComponent(LoadingPage)),
)(BaseView);
