//require('dotenv').config();

const stylesConfig = {
  BACKGROUND_URL: process.env.REACT_APP_BACKGROUND_URL,
  THEME: process.env.REACT_APP_THEME,
  APPNAME: process.env.REACT_APP_APPNAME,
  SHOW_APPNAME: process.env.REACT_APP_SHOW_APPNAME,
  APP_VERSION: process.env.REACT_APP_VERSION,
  LOGO_ALT: process.env.REACT_APP_LOGO_ALT,
  LOGO_LINK: process.env.REACT_APP_LOGO_LINK,
  FOOTER_LINK: process.env.REACT_APP_FOOTER_LINK,
  FOOTER_TEXT: process.env.REACT_APP_FOOTER_TEXT,
  LANG: process.env.REACT_APP_LANG,
  TC_LINK: process.env.REACT_APP_TC_LINK,
  TC_LINK_EN: process.env.REACT_APP_TC_LINK_EN,
  TC_LINK_DE: process.env.REACT_APP_TC_LINK_DE,
  COMPANY: process.env.REACT_APP_COMPANY,
  HELP_EMAIL: process.env.REACT_APP_HELP_EMAIL,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
};

export default stylesConfig;

export const appConfig = {
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
  ALLOW_SIGNUP: process.env.REACT_APP_ALLOW_SIGNUP,
  DEBUG_WS: process.env.REACT_APP_DEBUG_WS,
  URL: process.env.REACT_APP_URL,
  URL_REST: process.env.REACT_APP_URL_REST,
  URL_API: process.env.REACT_APP_URL_API,
  URL_WS: process.env.REACT_APP_URL_WS,
  URL_MP: process.env.REACT_APP_URL_MP,
  SHOW_MESSENGER_SELECTOR: process.env.REACT_APP_SHOW_MESSENGER_SELECTOR,
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE,
  GTM_KEY: process.env.REACT_APP_GTM_KEY,
  GTM_AUTH: process.env.REACT_APP_GTM_AUTH,
  GTM_PREVIEW: process.env.REACT_APP_GTM_PREVIEW,
  STATUS_PAGE_URL: process.env.REACT_APP_STATUS_PAGE_URL,
  GRAFANA_EMBED_URL: process.env.REACT_APP_GRAFANA_EMBED_URL,
  GRAFANA_URL: process.env.REACT_APP_GRAFANA_URL,
  EMAIL2SMS_URL: process.env.REACT_APP_EMAIL2SMS_URL,
  MT_API_URL: process.env.REACT_APP_MT_API_URL,
  BETA: process.env.REACT_APP_BETA === 'true',
  OPT_OUT_URL: process.env.REACT_APP_OPT_OUT_URL,
  INBOUND_NUMBERS_URL: process.env.REACT_APP_URL_INBOUND_NUMBERS,
  ESMES_SMSC_IDS_URL: process.env.REACT_APP_ESMES_SMSC_URL,
  USER_ACTIVITY_TIMEOUT_LOGOUT: process.env.REACT_APP_USER_ACTIVITY_TIMEOUT_LOGOUT,
};
