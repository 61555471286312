import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import SelectLanguage from '../SelectLanguage';

import stylesConfig from '../../../config';

const useStyles = makeStyles(() => ({
  copyrightCaption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 20,
    flex: 1,
  },
  loginFooter: {
    background: 'rgba(255, 255, 255, 0.7)',
    flexBasis: 64,
    display: 'flex',
  },
  footerLink: {
    textDecoration: 'none',
    color: grey[900],
    '&:hover': {
      color: grey[700],
    },
  },
}));

const LoginScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.loginFooter}>
      <Typography className={classes.copyrightCaption}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes.footerLink}
          href={`${stylesConfig.FOOTER_LINK}`}
        >
          {`© ${new Date().getFullYear()} | ${stylesConfig.FOOTER_TEXT} (v${stylesConfig.APP_VERSION})`}
        </a>
      </Typography>
      <SelectLanguage />
    </div>
  )
};

export default LoginScreen;
